:root {
  --sidebar-width: 300px;
}

.rs-container.rs-container-has-sidebar > .rs-sidebar {
  /* Override Rsuite inline styles */
  flex: 0 0 var(--sidebar-width) !important;
  width: var(--sidebar-width) !important;
}

.side-menu.rs-sidenav-default {
  height: 100%;
  width: var(--sidebar-width);
  position: relative;
}

.side-menu .rs-sidenav-body {
  padding: 30px;
}

.side-menu .rs-sidenav-nav > .rs-sidenav-item {
  /* Override Rsuite important property */
  margin-bottom: 10px !important;
  border-radius: 8px;
}

.side-menu .rs-sidenav-item.rs-sidenav-item-active {
  background: var(--theme-bg-primary-inverted) !important;
}

.side-menu .rs-sidenav-item {
  padding-left: 60px !important;
}

.side-menu .rs-sidenav-item svg {
  left: 25px !important;
}

.side-menu .rs-sidenav-item.rs-sidenav-item-active svg {
  fill: var(--theme-primary-cyan);

}

.side-menu .noover-logo {
  margin-left: 10px;
  margin-bottom: 25px;
}

.side-menu .sidebar-toggle.-close {
  position: absolute;
  right: 20px;
  top: 33px;
}

.side-menu .version-info {
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

@media print {
  .side-menu {
    display: none;
  }
}
