.user-list .search {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.user-list .pagination {
  width: 100%;
  margin-top: 16px;
  display: flex;
  justify-content: center;
}

.user-list .search-bar {
  margin-bottom: 16px;
}

.user-list .flag-filter {
  margin-bottom: 16px;
  width: 300px;
  margin-right: 16px;
}

.search-bar {
  display: flex;
  align-items: flex-end;
}

.search-bar .input {
  width: 200px;
  margin-right: 12px;
}

.search-bar .input input{
  margin-top: 4px;
}

.flag-filter .rs-picker{
  margin-top: 4px;
}
